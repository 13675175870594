import React from 'react'
import Loadable from 'react-loadable';

//    const kelly_colors_hex = [
//        '#FFB300', //Vivid Yellow
//        '#803E75', //Strong Purple
//        '#FF6800', //Vivid Orange
//        '#A6BDD7', //Very Light Blue
//        '#C10020', //Vivid Red
//        '#CEA262', //Grayish Yellow
//        '#817066', //Medium Gray
//
//        //The following don't work well for people with defective color vision
//        '#007D34', //Vivid Green
//        '#F6768E', //Strong Purplish Pink
//        '#00538A', //Strong Blue
//        '#FF7A5C', //Strong Yellowish Pink
//        '#53377A', //Strong Violet
//        '#FF8E00', //Vivid Orange Yellow
//        '#B32851', //Strong Purplish Red
//        '#F4C800', //Vivid Greenish Yellow
//        '#7F180D', //Strong Reddish Brown
//        '#93AA00', //Vivid Yellowish Green
//        '#593315', //Deep Yellowish Brown
//        '#F13A13', //Vivid Reddish Orange
//        '#232C16', //Dark Olive Green
//    ]

function makeTraces(points, logScaleX, logScaleY, labelAllCountries)
{
    let circles = {
        x: [],
        y: [],
        text: [],
        marker: { size: [], color: 'rgba(128, 62, 117, 0.5)' },
        customdata: [],
        mode: 'markers+text',
        type: 'scatter',
        textposition: "top center",
        hovertemplate: "<b>%{customdata[0]}</b><br>" +
                       "%{xaxis.title.text}: $%{x:.0f}<br>" +
                       "%{yaxis.title.text}: %{y:.0f}",
                       //"last 30 days as % of all deaths: %{customdata[1]:.0f}\%",
        name: 'Country (size reflects population)',
        showlegend: true
    }
    /*
    let lines = {
        x: [],
        y: [],
        mode: 'lines',
        line: { color: 'rgba(193, 0, 32, 0.5)' },
        hoverinfo: 'skip',
        name: '30-day change',
        visible: 'legendonly',
        showlegend: true
    }
    */

    // Pick some countries to label.
    if (!labelAllCountries) {
        let max_gdp = points[0][1]
        let max_deaths = points[0][2]
        for (var i = 1; i < points.length; i++) {
            const [alpha3, per_capita_gdp, per_capita_deaths, per_capita_deaths_30,
                population, name, always_label] = points[i]
            max_gdp = Math.max(max_gdp, per_capita_gdp)
            max_deaths = Math.max(max_deaths, per_capita_deaths)
        }

        const bucket_count_x = 10
        const bucket_count_y = 5
        // (x, y) => index into points
        const bucket_hash = new Map()
        for (var i = 0; i < points.length; i++) {
            const [alpha3, per_capita_gdp, per_capita_deaths, per_capita_deaths_30,
                population, name, always_label] = points[i]
            const bucket_x = Math.round(bucket_count_x * (
                logScaleX ? Math.log(per_capita_gdp) / Math.log(max_gdp)
                    : per_capita_gdp / max_gdp))
            const bucket_y = Math.round(bucket_count_y * (
                logScaleY ? Math.log(per_capita_deaths) / Math.log(max_deaths)
                    : per_capita_deaths / max_deaths))
            // JavaScript Map doesn't compare list contents when you use them to
            // index a hash, so compute a unique int.
            const bucket = bucket_y * bucket_count_x + bucket_x
            const current = bucket_hash.get(bucket)
            if (!current || points[current][4] < population) {
                bucket_hash.set(bucket, i)
            }
            points[i][6] = false
        }

        for (const i of bucket_hash.values()) {
            points[i][6] = true
        }
    }

    for (var i = 0; i < points.length; i++) {
        const [alpha3, per_capita_gdp, per_capita_deaths, per_capita_deaths_30,
            population, name, always_label] = points[i]
        if (labelAllCountries || always_label) {
            circles.text.push(alpha3)
        } else {
            // Push whitespace instead of null to work around
            // https://github.com/plotly/plotly.js/issues/5360
            circles.text.push(" ")
        }
        circles.x.push(per_capita_gdp)
        circles.y.push(per_capita_deaths)
        circles.marker.size.push(Math.max(5, Math.sqrt(population) / 500))
        circles.customdata.push([name])

        /*
        lines.x.push(per_capita_gdp)
        lines.y.push(per_capita_deaths)
        lines.x.push(per_capita_gdp)
        lines.y.push(per_capita_deaths_30)
        lines.x.push(null)
        lines.y.push(null)
        */
    }

    return {
        //'lines': lines,
        'circles': circles
    }
}

const LoadablePlot = Loadable({
    loader: () => import('react-plotly.js'),
    loading() {
        return <div>Loading...</div>;
    },
});

export class Covid19WealthGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {labelAllCountries: false}
    }

    render() {
        const { revision, points, logScaleX, logScaleY, labelAllCountries } = this.props
        const traces = makeTraces(points, logScaleX, logScaleY, labelAllCountries)

        const data = [traces.circles, /*traces.lines*/]
        const layout = {
            title: {text: "Reported COVID-19 Deaths by Wealth"},
            xaxis: {
                title: {text: "GDP/capita"},
                type: logScaleX ? 'log' : 'linear'
            },
            yaxis: {
                title: {text: "COVID-19 deaths/1M capita"},
                type: logScaleY ? 'log' : 'linear'
            },
            margin: {
                //l: 0,
                r: 0,
                //b: 0,
                //t: 0
            },
            legend: {"orientation": "h"},

            hovermode: 'closest',
            autosize: true,
            datarevision: revision
        }

        return <div>
                <LoadablePlot
                    data={data}
                    layout={layout}
                    useResizeHandler={true}
                    style={{width: "100%", height: "80vh"}}
                    revision={revision}
                />
            {/*<p>revision: {revision}</p>*/}
            </div>
    }
}

export function Sources(props) {
    const {sources} = props
    const sourceRows = sources.map((row) => {
        const dateStr = (new Date(row[2])).toLocaleString()
        return <tr key={row[1]}><td><a href={row[1]}>{row[0]}</a></td><td>{dateStr}</td></tr>
    });
    return <table>
            <tr><th>Data Set</th><th>Fetched On</th></tr>
            {sourceRows}
        </table>
}
