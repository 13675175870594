/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Layout from '../../components/layout'
import Content from '../../components/Content'
import Wrapper from '../../components/Wrapper'
import Hero from '../../components/Hero'
import SEO from '../../components/SEO'
import Disqus from '../../components/Disqus'
import { Covid19WealthGraph, Sources } from "../../components/Covid19WealthGraph.js"

import data from "./data.json"

const TestPage = props => {
  const slug = "covid19-wealth"
  const location = "/" + slug
  const title = "COVID-19 Deaths and Wealth"
  const description = title

    const [state, setState] = React.useState({
        logScaleX: true,
        logScaleY: false,
        labelAllCountries: false,
        revision: 1
    })

    const handleCheckbox = (event) => {
        setState({ ...state,
            [event.target.name]: event.target.checked,
            revision: state.revision + 1
        })
    }

  return (
    <Layout location={props.location}>
      <SEO
        title={title}
        description={description}
        path={slug}
        /*cover={page.frontmatter.cover && page.frontmatter.cover.publicURL}*/
      />

      <Hero
        /*heroImg={page.frontmatter.cover && page.frontmatter.cover.publicURL}*/
        title={title}
      />

      <Wrapper width="95%">
          <Covid19WealthGraph
            points={data.points}
            logScaleX={state.logScaleX}
            logScaleY={state.logScaleY}
            labelAllCountries={state.labelAllCountries}
            revision={state.revision}
      />

        <FormControlLabel
            control={<Checkbox checked={state.logScaleX} onChange={handleCheckbox} name="logScaleX" />}
            label="Log Scale for GDP"
        />

        <FormControlLabel
            control={<Checkbox checked={state.logScaleY} onChange={handleCheckbox} name="logScaleY" />}
            label="Log Scale for deaths"
        />

        <FormControlLabel
            control={<Checkbox checked={state.labelAllCountries} onChange={handleCheckbox} name="labelAllCountries" />}
            label="Label all countries"
        />

      </Wrapper>

      <Wrapper>
          <article>
      <Content>
              <h2>Explanation</h2>

      <p>
        Each circle represents a single country. The bigger the circle, the
      more people live in that country. The further to the right a circle is,
      the richer that country is. The further to the top a circle is, the more
      people have died from COVID-19. The position of the circle is corrected
      for the population on the country.
      </p>
      <p>
      You can mouse-over or tap on a circle to see some details about the country.
      You can click-drag or tap-drag to draw a box and zoom in.
      </p>

              <h2>Analysis</h2>

            <p>The countries who are getting the worst value for money are the ones that are
            at the top right of the graph: Bosnia and Herzegovina, Bulgaria, Italy, Belgium, USA,
            and Ireland. I'm not sure how complete the death numbers are in poor countries,
            but among countries with more than $50k GDP/capita, the ones that are getting
            great value for their money are New Zealand, Malaysia, Japan, Korea, Australia,
            and Taiwain. These are all either islands or have closed land borders. I don't
            know if that's significant, but it does appear that countries with
            borders that are easily closed do better.</p>

          <h2>Sources</h2>
          <Sources sources={data.sources} />
      </Content>
          </article>
      </Wrapper>

      <Wrapper as="aside">
        <Disqus
          slug={slug}
          title={title}
          location={props.location}
        />
      </Wrapper>

    </Layout>
  )
}

export default TestPage
